import React, { useCallback, useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import './Invoice.css'; // Import CSS file for styling

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const options = {
    cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
};

const resizeObserverOptions = {};

const maxWidth = 800;

const PdfRender = (props) => {
    const [containerRef, setContainerRef] = useState(null);
    const [containerWidth, setContainerWidth] = useState();

    console.log("Prop", props)
    const onResize = useCallback((entries) => {
        const [entry] = entries;

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    useResizeObserver(containerRef, resizeObserverOptions, onResize);
    return (
        <div className="Example">
    <div className="Example__container">
        <div className="Example__container__document">
            <img
                src={props.imageUrl} // Replace props.imageUrl with the URL of your image
                alt="Image" // Provide an appropriate alt text for accessibility
                style={{
                    maxWidth: '100%', // Adjust the styling as needed
                    height: 'auto',
                    display: 'block',
                }}
            />
        </div>
    </div>
</div>
    )
}

export default PdfRender