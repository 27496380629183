import React, { useState, useEffect } from 'react';
import PdfRender from './PdfRender';
import ImageComponent from './ImageComponent';

function PDFViewer() {
  const [pdfUrl, setPdfUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;
    console.log(currentUrl);
    const requestBody = {
      weblink: currentUrl
    };
    console.log(requestBody);
    // Fetch pre-signed URL from your backend API
    fetch('https://api.chattybao.com/oakter/getCustomerInvoicePdf', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data.presignedUrl)
        // Set the PDF URL received from the backend
        setPdfUrl(data.presignedUrl);
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error fetching pre-signed URL:', error);
        setIsLoading(false);
      });
  }, []);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
      {isLoading ? <h5 style={{ color: 'white' }}>{'Loading PDF...'}</h5> : null}
      {pdfUrl && (
        <ImageComponent pdfUrl={pdfUrl} />
      )}
    </div>
  );
}

export default PDFViewer;
