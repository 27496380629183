import React, { useCallback, useState } from 'react';
import { useResizeObserver } from '@wojtekmaj/react-hooks';

// function ImageComponent() {
//     return (
//         <div className="Example">
//             <div className="Example__container">
//                 <div className="Example__container__document">
//                     <img
//                         src="https://oakterinvoices.s3.ap-south-1.amazonaws.com/invoice-pdf/FP00019.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAYKLXEH5HUCVYARCZ%2F20240411%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20240411T192845Z&X-Amz-Expires=3600&X-Amz-Signature=23330bbb41596bda8d815ad247d2cae32d926d1a73fd6b1bd0a33318f88116eb&X-Amz-SignedHeaders=host&x-id=GetObject"
//                         alt="Invoice"
//                         style={{ maxWidth: '100%', height: 'auto', display: 'block' }}
//                     />
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default ImageComponent;

const resizeObserverOptions = {};


const ImageComponent = (props) => {
    const [containerRef, setContainerRef] = useState(null);
    const [containerWidth, setContainerWidth] = useState();

    console.log("Prop", props)
    const onResize = useCallback((entries) => {
        const [entry] = entries;

        if (entry) {
            setContainerWidth(entry.contentRect.width);
        }
    }, []);

    useResizeObserver(containerRef, resizeObserverOptions, onResize);
    return (
        <div className="Example">
    <div className="Example__container">
        <div className="Example__container__document">
            <img
                src={props.pdfUrl} // Replace props.imageUrl with the URL of your image
                alt="Image" // Provide an appropriate alt text for accessibility
                style={{
                    maxWidth: '100%', // Adjust the styling as needed
                    height: 'auto',
                    display: 'block',
                }}
            />
        </div>
    </div>
</div>
    )
}

export default ImageComponent
